// Add this polyfill to fix a warning with Redux
import 'symbol-observable';
import { createInertiaApp } from '@inertiajs/react';
import { createElement } from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'

createInertiaApp({
  resolve: (name) => {
    const pages = import.meta.glob('../pages/**/*.tsx')
    return pages[`../pages/${name}.tsx`]();
  },

  setup({ el, App, props }) {
    if(import.meta.env.VITE_SSR) {
      hydrateRoot(el as unknown as Element, <App {...props} /> as unknown as any) 
    } else {
      const root = createRoot(el)
      root.render(createElement(App, props) as unknown as any)
    }
  },
});
